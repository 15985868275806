import React, { useCallback, useState, useEffect } from "react";
import Spinner from "../ui/spinner";
import ReactDOM from "react-dom";
import DynamicTable from "@atlaskit/dynamic-table";
import styled from "styled-components";
import Tooltip from "@atlaskit/tooltip";
import { Link, useParams } from "react-router-dom";
import slugify from "slugify";
import CellValue from "../../utils/cell-value";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import Lozenge from "@atlaskit/lozenge";
import CopyIcon from "@atlaskit/icon/glyph/copy";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/base.css";
import "@inovua/reactdatagrid-enterprise/theme/default-light.css";
import DisplayDocumentByType, {
  DisplayDocumentLinkRow,
  DocumentFrame,
  DocumentLinkWrapper,
} from "./display-document-by-type";
import { useTranslation } from "react-i18next";
import toBoolean from "../../utils/to-boolean";
import Portal from "@atlaskit/portal";
import InlineEdit, { FlexMe } from "../forms/inline-edit";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import Button from "@atlaskit/button";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import DocumentTableNewRecord from "./document-table-new-record";
import DocumentTableEditRecord from "./document-table-edit-record";
import DocumentTableRemoveRecord from "./document-table-remove-record";
import DocumentTableRemoveComponentRecord from "./document-table-remove-component-record";
import useGlobalConfig from "../../hooks/use-global-config";
import AddCircleIcon from "@atlaskit/icon/glyph/add-circle";
import AddIcon from "@atlaskit/icon/glyph/add";
import Avatar from "@atlaskit/avatar";
import { format } from "date-fns";
import AvatarGroup from "@atlaskit/avatar-group";
import Drawer from "../ui/drawer";
import { NewPageInner } from "../../pages/new";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import AttachmentIcon from "@atlaskit/icon/glyph/attachment";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import { toast } from "react-toastify";
import EditIcon from "@atlaskit/icon/glyph/edit-filled";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { useAuth } from "../../providers/auth-provider";
import JiraFailedBuildStatusIcon from "@atlaskit/icon/glyph/jira/failed-build-status";

function DocumentsList({
  isPending,
  fields,
  documents,
  space,
  columns,
  availableDocumentTypes = null,
  savedColumns = null,
  onLimitChange,
  onSkipChange,
  limit,
  skip,
  sortHandle,
  sortColumn,
  sortDir,
  columnVisible,
  onColumnVisibleChange,
  columnsOrder,
  setColumnsOrder,
  showColumnMenuTool = true,
  editMode = false,
  actionsColumn = false,
  document_links = [],
  reload,
  contextDocument = null,
  footerRows = null,
  summaryReducer = null,
  height = 700,
  linkTypeId = 3,
  columnsWidths = {},
  columnsGroups = {},
  defaultColumnWidth = 200,
  colorField,
  bgField,
  colorColumn,
  addFormOpen,
  setAddFormOpen,
  createButton = false,
  columnsFlex = {},
  isDrawer = false,
  related = [],
  trashed = false,
  disableCreate = false,
  disableEdit = false,
  disableDelete = false,
  onRefCreated,
  pagination = null,
  setSkip = null,
  setPage = null,
}) {
  const data = documents?.data || [];
  const [gridRef, setGridRef] = useState(null);
  const { t } = useTranslation();
  const { space: slug } = useParams();
  const [isSavingSelect, setIsSavingSelect] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const { config: typesMapConfig } = useGlobalConfig("types_map");
  const [recordToRemove, setRecordToRemove] = useState(null);
  const nameField = fields?.find((i) => i.name === "name") || null;
  const [drawerId, setDrawerId] = useState(null);
  const filteredFields = fields?.filter((i) => {
    const isSection = i?.field?.type == "section";
    const isWidgets = i?.field?.type == "widgets";
    const isDivider = i?.field?.type == "divider";
    const isMessage = i?.field?.type == "message";
    const isHeading = i?.field?.type == "heading";
    const isSignature = i?.field?.type == "signature";
    const isGroup = i?.field?.type == "group";
    const isHidden = toBoolean(i?.hidden);
    return (
      i.name !== "name" &&
      !isSection &&
      !isHidden &&
      !isWidgets &&
      !isDivider &&
      !isMessage &&
      !isHeading &&
      !isSignature &&
      !isGroup
    );
  });
  const quickFormFields = fields?.filter((i) => toBoolean(i?.quick_form)) ?? [];
  const [newDrawerOpen, setNewDrawerOpen] = useState(false);
  const { run: runAllusers, data: allUsers } = useAsync();

  useEffect(() => {
    runAllusers(apiClient("allUsers"));
  }, []);

  useEffect(() => {
    onRefCreated && onRefCreated(gridRef);
  }, [gridRef]);

  useEffect(() => {
    if (!slug) return;
    setDrawerId(null);
  }, [slug]);

  const cellDOMProps = (cellProps) => {
    return {
      onClick: () => {
        gridRef.current.startEdit({
          columnId: cellProps.id,
          rowIndex: cellProps.rowIndex,
        });
      },
    };
  };

  const duplicateRecord = (record) => {
    if (isDuplicating) return;
    setIsDuplicating(true);
    apiClient(`workflow/documents/${record.id}/duplicate`, { method: "POST" })
      .then((response) => {
        toast.success(t("duplicated"));
        reload();

        if (gridRef.current) {
          const total = (pagination?.total ?? 0) + 1;

          const lastPage = Math.ceil(total / limit);
          const lastPageSkip = (lastPage - 1) * limit;
          const p = 1 + parseInt(lastPageSkip / limit);
          setSkip(lastPageSkip);
          setPage(p);
          gridRef.current.setSkip(lastPageSkip);
          const interval = setInterval(() => {
            gridRef.current.scrollToId(response?.data?.id);
            // gridRef.current.startEdit({ id: response?.id });
          }, 100);
          setTimeout(() => clearInterval(interval), 3000);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("duplicate_error"));
      })
      .finally(() => setIsDuplicating(false));
  };

  const DocumentTypeEditor = (props) => {
    console.log("PROPS", props);
    const field = props?.field ?? {};
    const document = null;

    const inputProps = {
      key: field.id,
      name: field.name,
      isRequired:
        (!!field.required || !!field?.must_have) && !toBoolean(field.read_only),
      // defaultValue: field?.field?.type === "checkbox" ? toBoolean(document.values[field.id]) : document.values[field.id],
      // isInvalid: error?.errors[field.name],
      // table_column: field?.table_column || [],
      // columns_values: document?.columns_values,
      isDisabled: toBoolean(field?.read_only ?? 0),
      // rawValues: document?.rawValues || [],
      autoFocus: false,
    };

    return (
      <InlineEdit
        value={props?.value}
        allUsers={allUsers}
        reload={reload}
        onSaved={() => reload && reload()}
        document={document}
        isRequired={!!field?.required || !!field?.must_have}
        field={field}
        fields={fields}
        // setErrors={setErrors}
        inputProps={inputProps}
        root={document}
        editable={true}
      />
    );
  };

  let gridGroups = columnsGroups?.groups ?? [];

  let gridColumns = [
    {
      name: "name",
      editable: false,
      header: () => {
        const label = nameField?.label || "Name";
        return <div title={label}>{label}</div>;
      },
      headerAlign: "center",
      visible: columnVisible?.includes("name"),
      showColumnMenuFilterOptions: false,
      defaultWidth: columnsWidths["name"],
      defaultFlex: columnsFlex["name"],
      group: columnsGroups?.columns?.["name"] ?? undefined,
    },
    ...(filteredFields?.map((field) => ({
      name: field.name,
      header: () => {
        const label =
          columns?.find((i) => i?.document_type_field_id === field?.id)
            ?.label ?? field.label;
        return <div title={label}>{label}</div>;
      },
      group: columnsGroups?.columns?.[field.name] ?? undefined,
      textAlign: textAlign(columns, field),
      headerAlign: "center",
      visible: columnVisible?.includes(field.name),
      cellDOMProps,
      editable:
        ["select"].includes(field?.field?.type) && editMode && !disableEdit,
      editor:
        field?.field?.type == "document-type"
          ? DocumentTypeEditor
          : ["select", "document-type"].includes(field?.field?.type)
          ? SelectEditor
          : undefined,
      editorProps:
        field?.field?.type == "document-type"
          ? {
              field,
            }
          : ["select"].includes(field?.field?.type)
          ? {
              idProperty: "id",
              dataSource:
                field?.options?.map((i) => ({
                  label: i.name,
                  value: i.value,
                  id: i.value,
                  name: i.name,
                })) ?? [],
              collapseOnSelect: true,
              clearIcon: null,
              cellDOMProps,
            }
          : undefined,
      sortable: ![
        "list",
        "section",
        "list",
        "multiselect",
        "table",
        "textarea",
        "wysywig",
        "document-type-multi",
      ].includes(field?.field?.type),
      defaultWidth: columnsWidths[field.name],
      defaultFlex: columnsFlex[field?.name],

      onRender: (cellProps, { data: row }) => {
        cellProps.style.overflow = "visible";
        cellProps.style.position = "relative";

        if (colorColumn == field.name) {
          const document = data?.find((i) => i?.id == row?.id);
          if (document) {
            cellProps.style.background = bgField
              ? document?.values[bgField]
              : "transparent";
            cellProps.style.color = colorField
              ? document?.values[colorField]
              : "0052CC";
          }
        }
      },
    })) || []),
    ...(availableDocumentTypes?.map((dt) => ({
      id: dt.documentTypeName,
      name: dt.documentTypeName,
      group: columnsGroups?.columns?.[dt.documentTypeName] ?? undefined,
      editable: false,
      header: () => {
        const label = dt.label;
        return <div title={label}>{label}</div>;
      },
      sortable: true,
      type: "document-type",
      headerAlign: "center",
      visible: columnVisible?.includes(dt.documentTypeName),
      defaultWidth: columnsWidths[dt.documentTypeName],
      defaultFlex: columnsFlex[dt.documentTypeName],
    })) || []),
    {
      name: "users",
      editable: false,
      header: "Użytkownicy",
      headerAlign: "center",
      width: 100,
      visible: columnVisible?.includes("users"),
      sortable: false,
      defaultWidth: columnsWidths["sortable"],
      group: columnsGroups?.columns?.["users"] ?? undefined,
    },
    {
      name: "uuid",
      editable: false,
      group: columnsGroups?.columns?.["uuid"] ?? undefined,
      header: "Uuid",
      headerAlign: "center",
      width: 250,
      visible: columnVisible?.includes("uuid"),
      sortable: false,
      defaultWidth: columnsWidths["uuid"],
    },
  ];

  const creatable =
    space?.document_type?.creatable === true ||
    space?.document_type?.creatable === "true";
  const actionsHeader =
    creatable && createButton && !disableCreate ? (
      <Button
        appearance="primary"
        iconBefore={<AddIcon />}
        shouldFitContainer
        onClick={() => {
          setAddFormOpen(true);
        }}
        size="small"
      />
    ) : (
      ""
    );

  if (actionsColumn) {
    gridColumns = [
      ...gridColumns,
      {
        name: "actions",
        header: actionsHeader,
        width: 100,
        editable: false,
        sortable: false,
        defaultWidth: 100,
        visible: actionsColumn,
        locked: "end",
        showInContextMenu: false,
      },
    ];
  }

  const RowActions = ({ document: d }) => {
    return (
      <RowActionsWrapper>
        {!disableEdit && (
          <Button
            iconBefore={<EditFilledIcon label="Star icon" size="small" />}
            appearance="subtle"
            onClick={() => {
              setRecordToEdit(d);
            }}
          />
        )}
        {!disableDelete && (
          <Button
            iconBefore={<TrashIcon label="Star icon" size="small" />}
            appearance="subtle"
            onClick={() => {
              setRecordToRemove(d);
            }}
          />
        )}
        {!disableCreate && creatable && (
          <Tooltip content={t("duplicate")}>
            <Button
              iconBefore={<CopyIcon label="Star icon" size="small" />}
              isDisabled={isDuplicating}
              appearance="subtle"
              onClick={() => {
                duplicateRecord(d);
              }}
            />
          </Tooltip>
        )}
      </RowActionsWrapper>
    );
  };

  const gridData = data.map((document) => ({
    id: document.id,
    key: document.id,
    name: (
      <RowHeader
        trashed={!!document?.deleted_at}
        document={document}
        space={space}
        bgField={bgField}
        colorField={colorField}
        colorColumn={colorColumn}
        onSelected={() => setDrawerId(document.id)}
        isDrawer={isDrawer}
        reload={reload}
        fields={filteredFields}
        linkable={availableDocumentTypes}
      />
    ),
    ...filteredFields?.reduce((acc, field) => {
      const value = document.values[field.name];
      const inputProps = {
        key: `${field.id}_${document.id}`,
        name: field.name,
        isRequired:
          (!!field.required || !!field?.must_have) &&
          !toBoolean(field.read_only),
        defaultValue:
          field?.field?.type === "checkbox"
            ? toBoolean(document.values[field?.name])
            : document.values[field?.name],
        isInvalid: false,
        table_column: field?.table_column || [],
        columns_values: document?.columns_values,
        isDisabled: toBoolean(field?.read_only ?? 0),
        rawValues: document?.rawValues || [],
        autoFocus: false,
      };
      if (["select"].includes(field?.field?.type)) {
        const defaultValue =
          field?.field?.type === "checkbox"
            ? toBoolean(document.values[field?.name])
            : document.values[field?.name];
        const label = field.options
          .map((i) => ({ label: i.name, value: i.value }))
          .find((i) => i.value == value)?.label;

        // acc[field.name] = <div>test {label || defaultValue}</div>;
        if (editMode && !disableEdit) {
          acc[field.name] = <SelectEdit>{label || defaultValue}</SelectEdit>;
        } else {
          acc[field.name] = <div>{label || defaultValue}</div>;
        }
        // acc[field.name] = <InlineEdit key={`${document.id}-${field.id}`} value={value} document={document} field={field} inputProps={inputProps} editable={false} onSaved={reload} />;
      } else if (["document-type"].includes(field?.field?.type)) {
        let isFieldEditable = editMode && !disableEdit;
        acc[field.name] = (
          <div
            style={{
              overflow: "visible",
              zIndex: 205,
            }}
          >
            <InlineEdit
              root={document}
              noLinkThrough
              usePortal
              gridRef={gridRef}
              allUsers={allUsers}
              related={related}
              key={`${document.id}-${field.id}`}
              value={value}
              document={document}
              field={field}
              inputProps={inputProps}
              editable={isFieldEditable}
              onSaved={reload}
            />
          </div>
        );
      } else {
        let isFieldEditable = editMode && !disableEdit;
        if (["document-type-multi"].includes(field?.field?.type)) {
          isFieldEditable = false;
        }
        acc[field.name] = (
          <InlineEdit
            allUsers={allUsers}
            related={related}
            key={`${document.id}-${field.id}`}
            value={value}
            document={document}
            field={field}
            inputProps={inputProps}
            editable={isFieldEditable}
            onSaved={reload}
          />
        );
      }
      return acc;
    }, {}),
    ...(availableDocumentTypes?.reduce((acc, dt) => {
      acc[dt.documentTypeName] = findRelationName(
        dt.value,
        document,
        dt,
        setDrawerId
      );
      return acc;
    }, {}) || []),
    users: (
      <div>
        <AvatarGroup
          appearance="stack"
          maxCount={3}
          size="small"
          data={document?.users
            ?.filter((u) => !u?.system)
            ?.map((user) => {
              return {
                email: user?.user?.email,
                key: user?.id,
                name: `${user?.user?.first_name} ${user?.user?.last_name}`,
                href: "#",
                src: user?.user?.avatar_url,
              };
            })}
        />
      </div>
    ),
    uuid: <small>{document?.uuid ?? "-"}</small>,
    ...(actionsColumn ? { actions: <RowActions document={document} /> } : {}),
  }));

  const gridStyle = { minHeight: height };

  const dataSource = useCallback(() => {
    return Promise.resolve({
      data: gridData,
      count: parseInt(documents?.pagination?.total ?? 0),
    });
  }, [gridData, parseInt(documents?.pagination?.total ?? 0)]);

  const i18n = {};

  for (const [key, value] of Object.entries(ReactDataGrid.defaultProps.i18n)) {
    i18n[key] = t(`grid_${key}`);
  }

  let columnsOrderWithActions = columnsOrder;
  if (
    actionsColumn &&
    columnsOrder &&
    columnsOrderWithActions &&
    !columnsOrderWithActions?.includes("actions")
  ) {
    columnsOrderWithActions = [...columnsOrderWithActions, "actions"];
  }

  const rowStyle = ({ data: { id } }) => {
    const row = data?.find((i) => i?.id == id);

    if (colorColumn) {
      return {
        color: "black",
        backgroundColor: "transparent",
      };
    }

    return {
      color: colorField && row ? row?.values[colorField] : "black",
      backgroundColor: bgField && row ? row?.values[bgField] : "transparent",
    };
  };

  return (
    <Wrapper>
      <ReactDataGrid
        ref={gridRef}
        onReady={setGridRef}
        // renderInPortal={true}
        groups={gridGroups}
        // enableClipboard
        idProperty="id"
        licenseKey="AppName=ERPSPACEApp,Company=ERPSPACE,ExpiryDate=2024-09-27T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=ERPSPACELicenseRef,Z=-9599080701641087644-145950022-208167493317874277531361469402"
        i18n={i18n}
        style={gridStyle}
        editable={editMode && !disableEdit}
        columns={gridColumns}
        activateRowOnFocus={false}
        allowUnsort={false}
        enableSelection={false}
        minRowHeight={45}
        rowHeight={null}
        // virtualized={false}
        limit={limit}
        skip={skip}
        loading={isPending || isSavingSelect || isDuplicating}
        pagination="remote"
        rowStyle={rowStyle}
        onSkipChange={(s) => {
          onSkipChange(s);
        }}
        onLimitChange={(l) => {
          onLimitChange(l);
        }}
        onEditValueChange={(row) => {
          setIsSavingSelect(true);
          apiClient(`workflow/document/${row.rowId}`, {
            method: "PATCH",
            data: {
              [row.columnId]: row.value,
            },
          })
            .then(() => {
              reload();
            })
            .catch(() => {
              toast.error(t("inline_edit_error"));
            })
            .finally(() => setIsSavingSelect(false));
        }}
        showColumnMenuGroupOptions={false}
        showColumnMenuLockOptions={false}
        onSortInfoChange={sortHandle}
        sortInfo={{ name: sortColumn, dir: sortDir }}
        onColumnVisibleChange={onColumnVisibleChange}
        columnOrder={columnsOrderWithActions}
        onColumnOrderChange={setColumnsOrder}
        summaryReducer={summaryReducer}
        footerRows={footerRows}
        dataSource={dataSource}
      />

      {editMode && recordToEdit && !isDrawer && (
        <DocumentTableEditRecord
          open={true}
          onSubmit={(formData) => {
            setRecordToEdit(null);
          }}
          onUpdated={() => reload && reload()}
          documentTypeId={space?.document_type?.id}
          editable={editMode}
          fields={quickFormFields}
          record={recordToEdit}
          columns={columns}
          root={contextDocument}
          document={contextDocument}
          document_links={document_links}
          linkTypeId={linkTypeId}
          onClose={() => {
            setRecordToEdit(null);
            reload && reload();
          }}
        />
      )}

      {editMode && recordToEdit && isDrawer && (
        <Drawer
          id={recordToEdit?.id}
          space={space?.slug}
          module={space?.module?.slug}
          category={space?.category?.slug}
          onReload={() => {
            reload && reload();
          }}
          onClose={() => {
            setRecordToEdit(null);
            reload && reload();
          }}
        />
      )}

      {editMode && addFormOpen && !isDrawer && (
        <DocumentTableNewRecord
          open={addFormOpen}
          onSubmit={(formData) => {
            const item = {
              ...formData,
              id: uuidv4(),
              added: true,
            };
          }}
          editable={editMode}
          fields={quickFormFields}
          columns={columns}
          document_links={document_links}
          document={contextDocument}
          documentTypeId={space?.document_type?.id}
          linkTypeId={linkTypeId}
          onClose={() => {
            setAddFormOpen(false);
            reload && reload();
          }}
        />
      )}
      {editMode && addFormOpen && isDrawer && (
        <NewDocumentDrawer
          // onSubmit={formData => {
          //     const item = {
          //         ...formData,
          //         id: uuidv4(),
          //         added: true,
          //     };

          //     console.log(item);
          // }}
          // editable={editMode}
          // fields={quickFormFields}
          // columns={columns}
          document_links={document_links}
          document={contextDocument}
          // documentTypeId={space?.document_type?.id}
          space={space}
          linkTypeId={linkTypeId}
          onClose={() => {
            setAddFormOpen(false);
            reload && reload();
          }}
        />
      )}
      {editMode &&
        recordToRemove &&
        typesMapConfig?.componentId !== linkTypeId && (
          <DocumentTableRemoveRecord
            document={contextDocument}
            linkId={recordToRemove?.id}
            onRemoved={() => {
              setRecordToRemove(false);
              reload && reload();
            }}
            onClose={() => setRecordToRemove(false)}
          />
        )}
      {editMode &&
        recordToRemove &&
        typesMapConfig?.componentId == linkTypeId && (
          <DocumentTableRemoveComponentRecord
            document={contextDocument}
            linkId={recordToRemove?.id}
            onRemoved={() => {
              setRecordToRemove(false);
              reload && reload();
            }}
            onClose={() => setRecordToRemove(false)}
          />
        )}
      {drawerId && (
        <Drawer
          id={drawerId}
          space={space?.slug}
          module={space?.module?.slug}
          category={space?.category?.slug}
          onReload={() => {
            reload && reload();
          }}
          onClose={() => {
            setDrawerId(null);
            reload && reload();
          }}
        />
      )}
    </Wrapper>
  );
}

function RowHeader({
  document,
  space,
  colorField,
  colorColumn,
  bgField,
  isDrawer = false,
  onSelected = null,
  trashed = false,
  reload,
  fields,
  linkable,
}) {
  let color = colorField ? document?.values[colorField] : "0052CC";
  let bgColor = bgField ? document?.values[bgField] : "transparent";

  if (colorColumn) {
    color = "0052CC";
    bgColor = "transparent";
  }

  if (colorColumn == "name") {
    color = colorField ? document?.values[colorField] : "0052CC";
    bgColor = bgField ? document?.values[bgField] : "transparent";
  }
  // const column = color

  if (trashed) {
    return (
      <RowLinkWrapperOuter color={color} bgColor={bgColor}>
        <DocumentLinkRow>
          {trashed && <RestoreButton document={document} onSuccess={reload} />}
          <DocumentLinkIcon>
            <Page16Icon />
          </DocumentLinkIcon>
          {document.name}
          {document?.files?.length > 0 && (
            <>
              <AttachmentIconWrapper>
                <AttachmentIcon />
              </AttachmentIconWrapper>
            </>
          )}
          <div
            style={{
              opacity: 0.7,
              fontSize: "11px",
              margin: "0 10px",
              fontWeight: "normal",
            }}
          >
            {format(new Date(document?.deleted_at), "yyyy-MM-dd HH:mm")}
          </div>
        </DocumentLinkRow>
      </RowLinkWrapperOuter>
    );
  }

  let isMissing = fields?.some((f) => {
    const value = document?.values[f?.name];
    const isRequired =
      (toBoolean(f.required) || toBoolean(f?.must_have)) &&
      !toBoolean(f.read_only);
    return !value && isRequired;
  });

  if (isDrawer) {
    return (
      <RowLinkWrapperOuter color={color}>
        <RowLinkDrawer
          document={document}
          space={space}
          onSelected={() => {
            onSelected();
          }}
        >
          <DocumentLinkRow>
            <DocumentLinkIcon>
              <Page16Icon />
            </DocumentLinkIcon>
            {document.name}
            {document?.files?.length > 0 && (
              <>
                <AttachmentIconWrapper>
                  <AttachmentIcon />
                </AttachmentIconWrapper>
              </>
            )}
            {isMissing && (
              <div style={{ color: "red" }}>
                <MissingWrapper>
                  <JiraFailedBuildStatusIcon size="small" />
                </MissingWrapper>
              </div>
            )}
          </DocumentLinkRow>
        </RowLinkDrawer>
      </RowLinkWrapperOuter>
    );
  }

  return (
    <RowLinkWrapperOuter color={color} bgColor={bgColor}>
      <RowLink document={document} space={space}>
        <DocumentLinkRow>
          <DocumentLinkIcon>
            <Page16Icon />
          </DocumentLinkIcon>
          {document.name}
          {document?.files?.length > 0 && (
            <>
              <AttachmentIconWrapper>
                <AttachmentIcon />
              </AttachmentIconWrapper>
            </>
          )}
          {isMissing && (
            <div style={{ color: "red" }}>
              <MissingWrapper>
                <JiraFailedBuildStatusIcon size="small" />
              </MissingWrapper>
            </div>
          )}
        </DocumentLinkRow>
      </RowLink>
    </RowLinkWrapperOuter>
  );
}

function RestoreButton({ document, onSuccess }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  let deletable = document?.document_type?.deletable ?? false;

  if (!deletable) {
    const documentUser = document?.users?.find((u) => u?.user?.id == user?.id);
    if (!documentUser) {
      return null;
    }

    if (documentUser?.role?.name !== "Owner") {
      return null;
    }
  }

  return (
    <>
      <Button
        size="small"
        style={{
          marginRight: "10px",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {t("restore")}
      </Button>
      {isOpen && (
        <RestoreDocumentModal
          document={document}
          onClose={() => setIsOpen(false)}
          onRestored={() => {
            setIsOpen(false);
            onSuccess && onSuccess();
          }}
          onError={() => setIsOpen(false)}
        />
      )}
    </>
  );
}

function RestoreDocumentModal({ document, onRestored, onError, onClose }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const restore = () => {
    if (isLoading) return;
    setIsLoading(true);
    apiClient(`workflow/documents/${document.id}/restore`, { method: "POST" })
      .then(() => {
        toast.success(t("restored"));
        onRestored && onRestored();
        close();
      })
      .catch(() => {
        toast.error(t("restore_error"));
        onError && onError();
      });
  };

  return (
    <ModalTransition>
      <Modal
        isLoading={isLoading}
        actions={[
          { text: t("restore"), onClick: restore },
          { text: t("attachment_cancel"), onClick: () => onClose && onClose() },
        ]}
        onClose={() => onClose && onClose()}
        heading={t("restore")}
        appearance="danger"
      ></Modal>
    </ModalTransition>
  );
}

const RowLinkWrapperOuter = styled.div`
  a {
    color: ${(props) => props.color};
    background-color: ${(props) => props.bgColor};
  }
`;

function RowLink({ document, space, children }) {
  const path =
    `${space?.category?.module?.slug}/${space.category?.slug}/${space.slug}`.toLowerCase();
  return (
    <Link to={`/${path}/${document.id}`}>
      <RowLinkWrapper>{children}</RowLinkWrapper>
    </Link>
  );
}

function RowLinkDrawer({ document, space, children, onSelected }) {
  const path =
    `${space?.category?.module?.slug}/${space.category?.slug}/${space.slug}`.toLowerCase();
  return (
    <Link
      to={`/${path}/${document.id}`}
      onClick={(e) => {
        e.preventDefault();
        onSelected();
      }}
    >
      <a href={`/${path}/${document.id}`}>
        <RowLinkWrapper>{children}</RowLinkWrapper>
      </a>
    </Link>
  );
}

export default DocumentsList;

const Wrapper = styled.div``;

const DocumentLinkRow = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
`;

const DocumentLinkIcon = styled.div`
  width: 16px;
  margin-right: 5px;
`;

const AttachmentIconWrapper = styled.div`
  width: 16px;
  margin-left: 5px;
  padding-top: 3px;
  color: #aaa;
`;

const MissingWrapper = styled.div`
  width: 16px;
  margin-left: 5px;
  padding-top: 2px;
  color: red;
`;

const RowLinkWrapper = styled.div`
  font-weight: bold;
`;

const RowSpacer = styled.div`
    height: 30px;value?.dir
    display: flex;
    align-items: center;
    justify-content: center;
`;

function findRelationName(documentTypeId, document, dt, setDrawerId) {
  const links = document?.links?.filter(
    (i) => i?.document_type?.id === documentTypeId
  );
  if (links?.length === 0) return "-";

  return (
    <LinksWrapper>
      {links?.map((link, index) => {
        const name = link?.values?.find((i) => i?.name == "name")?.value;
        return (
          <div>
            <Link
              to={`/${dt.uri}/${link.id}`}
              onClick={(e) => {
                e.preventDefault();
                setDrawerId(link?.id);
              }}
            >
              {name}
            </Link>
            {/* {index !== links?.length - 1 ? <>, </> : null} */}
          </div>
          // <ListDocumentLinkWrapper key={link?.id}>
          //     <DocumentFrame height="30px">
          //         <DocumentLinkWrapper margin={true}>
          //             <Link to={`/${dt.uri}/${link.id}`}>
          //                 <DisplayDocumentLinkRow height="30px">
          //                     {name}
          //                 </DisplayDocumentLinkRow>
          //             </Link>
          //         </DocumentLinkWrapper>

          //     </DocumentFrame>
          // </ListDocumentLinkWrapper>
        );
      })}
    </LinksWrapper>
  );
}

const ListDocumentLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RowActionsWrapper = styled.div`
  display: flex;
`;

function textAlign(columns, field) {
  const align = columns?.find(
    (i) => i?.document_type_field_id === field?.id
  )?.align;

  if (align === "right") return "end";
  if (align === "left") return "start";
  if (align === "center") return "center";

  return "center";
}

const UsersWrapper = styled.div`
  position: relative;
`;

function NewDocumentDrawer({
  onClose,
  space,
  linkTypeId,
  document,
  document_links,
}) {
  const {
    run,
    isPending: isCreating,
    error,
    isError: isCreatingError,
  } = useAsync();

  const handleSubmit = (data) => {
    // run(apiClient(`workflow/document-type/${space?.document_type?.id}/document`, { data }))
    //     .then(response => {
    //         window.location.href = `${spacePath(space)}/${response.id}`;
    //     })
    //     .catch(() => {
    //         toast.error(t("new_error"));
    //     });

    run(
      apiClient(`workflow/document-type/${space?.document_type?.id}/document`, {
        data: {
          ...data,
          links: [
            { id: document?.id, type: linkTypeId },
            ...document_links
              ?.map((documentLink) => {
                const linkName = documentLink.name;
                const linkData = links[linkName] ? links[linkName] : null;
                if (!linkData) return;
                return { id: linkData.value, type: 3 };
              })
              .filter((i) => i != null),
          ],
        },
      })
    )
      .then(() => onClose())
      .catch(() => {
        toast.error(t("new_error"));
      });
  };

  return (
    <div className="newDocumentDrawerWrapper" tabIndex="-1">
      <div className="newDocumentDrawerClose" onClick={() => onClose()}>
        &times;
      </div>

      <div className="">
        <NewPageInner
          contextDocument={document}
          space={space}
          onSuccess={onClose}
          handleSubmit={handleSubmit}
          isCreating={isCreating}
          error={error}
          isCreatingError={isCreatingError}
        />
      </div>

      <style
        dangerouslySetInnerHTML={{
          __html: `
            .newDocumentDrawerWrapper {
                background-color: rgb(255, 255, 255);
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                height: 100vh;
                line-height: 24px;
                max-width: 800px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                overflow-y: auto;
                padding-bottom: 24px;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                position: fixed;
                right: 0px;
                tab-size: 4;
                top: 0px;
                width: 90%;
                z-index: 400;
            }

            .newDocumentDrawerClose {
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                cursor: pointer;
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                font-size: 24px;
                height: 56px;
                left: 0px;
                line-height: 24px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 21px;
                position: absolute;
                tab-size: 4;
                top: 0px;
                width: 41.765625px;
            }
            `,
        }}
      ></style>
    </div>
  );
}

export const SelectWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-right: 10px;
  padding: 5px 10px;
`;

const SelectEdit = ({ children }) => {
  const [hover, setHover] = useState(false);

  return (
    <SelectWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FlexMe>{children}</FlexMe>
      {hover && <EditIcon size="small" />}
    </SelectWrapper>
  );
};

const LinksWrapper = styled.div`
  white-space: pre-wrap;
`;

const InlinePortal = ({ children, gridRef }) => {
  const cellRef = React.useRef(null); // Ref to the cell for positioning

  console.log(cellRef.current?.getBoundingClientRect());

  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  // Update position whenever the window is resized or scrolled
  React.useEffect(() => {
    const updatePosition = () => {
      if (cellRef.current) {
        const rect = cellRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top + window.scrollY, // Adjust for scroll position
          left: rect.left + window.scrollX, // Adjust for scroll position
        });
      }
    };

    // Run initially to get the position
    updatePosition();

    // Add event listeners for resize, scroll, and grid scroll
    const gridElement = gridRef?.current?.grid?.current; // Access the grid's DOM element
    gridElement?.addEventListener("scroll", updatePosition); // Listen for grid scroll

    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
      gridElement?.removeEventListener("scroll", updatePosition);
    };
  }, [gridRef]);

  return (
    <>
      <div
        ref={cellRef}
        style={{
          position: "relative",
          backgroundColor: "red",
        }}
      ></div>

      {ReactDOM.createPortal(
        <div
          style={{
            position: "absolute",
            top: position.top + "px", // Use updated position from state
            left: position.left + "px", // Use updated position from state
            zIndex: 205,
          }}
        >
          {children}
        </div>,
        document.body // Render the portal outside the grid
      )}
    </>
  );
};
