import React, { useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../ui/spinner";
import InlineMessage from "@atlaskit/inline-message";
import styled from "styled-components";
import DocumentsList from "./documents-list";
import useDocumentsList, { mapFilter } from "../../hooks/use-documents-list";
import useDocumentStatuses from "../../hooks/use-document-statuses";
import SectionMessage from "@atlaskit/section-message";
import useAvailableDocumentTypes from "../../hooks/use-available-document-types";
import { useTranslation } from "react-i18next";
import useSpace from "../../hooks/use-space";
import toBoolean from "../../utils/to-boolean";
import Filters from "../filters/filters";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { toast } from "react-toastify";
import { ButtonGroup } from "@atlaskit/button";
import Button from "@atlaskit/button/custom-theme-button";
import PageHeader from "@atlaskit/page-header";
import { Helmet } from "react-helmet";
import documentName from "../../utils/document-name";
import title from "../../title";
import Select from "@atlaskit/select";
import { v4 as uuidv4 } from "uuid";
import { parseCurrency } from "../../utils/cell-value";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";
import AddIcon from "@atlaskit/icon/glyph/add";

const newHash = () => {
  const max = 1_000_000_000;
  return Math.floor(Math.random() * max);
};

function Documents({
  documentTypeId,
  document,
  columns = [],
  document_links = [],
  slug,
  editMode = false,
  linkTypeId = 3,
  columnsWidths = {},
  columnsGroups = {},
  defaultColumnWidth = 200,
  colorField,
  colorColumn,
  bgField,
  filtersEnabled = false,
  showColumnMenuTool = false,
  currentSection,
  createButton = false,
  onReload,
  noColumnOrder = false,
  hideTitle = false,
  isDrawer = false,
  hideCreate = false,
  disableCreate = false,
  disableEdit = false,
  disableDelete = false,
  useColumnsOrder = false,
  config = {},
  defaultSortDir = 1,
}) {
  const { t } = useTranslation();
  // const gridRef = React.useRef();
  const [gridRef, setGridRef] = useState(null);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDir, setSortDir] = useState(defaultSortDir ?? 1);
  const [filters, setFilters] = useState(null);
  const {
    run,
    isPending: isCreating,
    error,
    isError: isCreatingError,
  } = useAsync();
  const [filtersOpen, setFiltersOpen] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [exporting, setExporting] = useState(false);
  const { statuses } = useDocumentStatuses(documentTypeId);
  const { data: availableDocumentTypes, isLoaded: isLoadedDocumentTypes } =
    useAvailableDocumentTypes(documentTypeId);
  //   const [columnsOrder, setColumnsOrder] = useState([
  //     "name",
  //     ...columns
  //       ?.filter((i) => !toBoolean(i.hidden))
  //       ?.filter((i) => {
  //         if (i.type == "relation") {
  //           return availableDocumentTypes
  //             ?.map((i) => i?.value)
  //             ?.includes(i?.document_type_id);
  //         }
  //         return true;
  //       })
  //       ?.map((i) => {
  //         if (i.type == "relation") {
  //           return `document.${i?.document_type_id}`;
  //         }
  //         return i.name;
  //       }),
  //   ]);
  const [columnsOrder, setColumnsOrder] = useState(null);
  // noColumnOrder &&
  // !space?.document_type?.config?.columnsOrder &&
  // !useColumnsOrder
  //   ? undefined
  //   : columnsOrder
  const [columnsVisible, setColumnsVisible] = useState([
    "name",
    ...columns?.filter((i) => !toBoolean(i.hidden))?.map((i) => i.name),
  ]);
  const [addFormOpen, setAddFormOpen] = useState(false);

  const localStorageKey = `dt-columns-${documentTypeId}`;

  const allFilters = useMemo(
    () => ({
      ...filters,
      filters: [
        ...(filters?.filters ?? []),
        // {
        //     id: 0,
        //     column: document?.document_type_id,
        //     operator: "IS",
        //     type: "document_type",
        //     value: {
        //         data: document,
        //         value: document?.id,
        //         label: "Document",
        //     }
        // }
      ],
    }),
    [filters, document?.document_type_id, document?.id]
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [hash, setHash] = useState(() => newHash());
  const [trashed, setTrashed] = useState(false);

  const [skip, setSkip] = useState(0);
  const {
    isPending,
    fields,
    documents,
    savedColumns,
    isLoadedFields,
    reload,
    exportList,
    columns: documentsColumns,
    related,
    pagination,
    newExportList,
  } = useDocumentsList(
    documentTypeId,
    page,
    limit,
    sortColumn,
    sortDir,
    allFilters,
    null,
    null,
    document?.id,
    null,
    columnsVisible,
    availableDocumentTypes,
    false,
    false,
    trashed
  );
  // const { isPending, fields, documents, columns, toggleColumn, savedColumns, isIdle, isLoadedFields, exportList, newExportList, reload, related } = useDocumentsList(documentTypeId, page, limit, sortColumn, sortDir, filters, null, queryFilterId, documentFilterId, null, columnsVisible, availableDocumentTypes, disableLinks);

  const {
    space,
    isPending: isPendingSpace,
    notFound,
    reload: reloadSpace,
    isLoaded,
  } = useSpace(slug);

  useEffect(() => {
    if (isPending) return;
    if (isPendingSpace) return;

    if (space?.document_type?.config?.columnsOrder && useColumnsOrder) {
      setColumnsOrder(space?.document_type?.config?.columnsOrder);
    }
  }, [isPending, isPendingSpace, space]);

  useEffect(() => {
    if (!isLoaded) return;
    if (!isLoadedFields) return;
    if (!isLoadedDocumentTypes) return;

    if (window.localStorage.getItem(localStorageKey)) {
      setColumnsVisible(
        JSON.parse(window.localStorage.getItem(localStorageKey))
      );
    } else {
      if (space?.document_type?.config?.columnsVisible) {
        setColumnsVisible(space?.document_type?.config?.columnsVisible);
      }
    }

    if (window.localStorage.getItem(`dt-columns-orders-${documentTypeId}`)) {
      setColumnsOrder(
        JSON.parse(
          window.localStorage.getItem(`dt-columns-orders-${documentTypeId}`)
        )
      );
    } else {
      if (space?.document_type?.config?.columnsOrder) {
        setColumnsOrder(space?.document_type?.config?.columnsOrder);
      } else {
        setColumnsOrder(null);
      }
    }
  }, [isLoaded, isLoadedFields, isLoadedDocumentTypes]);

  useEffect(() => {
    if (!isLoaded) return;
    if (!isLoadedFields) return;
    if (!isLoadedDocumentTypes) return;

    window.localStorage.setItem(
      localStorageKey,
      JSON.stringify(columnsVisible)
    );
    window.localStorage.setItem(
      `dt-columns-orders-${documentTypeId}`,
      JSON.stringify(columnsOrder)
    );
  }, [
    columnsVisible,
    columnsOrder,
    isLoaded,
    isLoadedFields,
    isLoadedDocumentTypes,
  ]);

  useEffect(() => {
    setPage(1);
  }, [trashed]);

  const spaceConfig = space?.config;
  const exportEnabled = !spaceConfig?.exportDisabled;
  const sortHandle = useCallback((value) => {
    setSortColumn(value?.name ?? "id");
    setSortDir(parseInt(value?.dir));
  }, []);
  const filteredFields = fields
    ?.filter((f) => !["list"].includes(f?.field?.type))
    .filter((i) => i?.field?.type !== "section");
  const onColumnVisibleChange = useCallback(({ column, visible }) => {
    const name = column?.name;

    if (visible) {
      setColumnsVisible((v) => [...v, name]);
    } else {
      setColumnsVisible((v) => v?.filter((i) => i !== name));
    }
  }, []);

  const updatePage = (l, s) => {
    const p = 1 + parseInt(s / l);
    setSkip(s);
    setLimit(l);
    setPage(p);
  };

  if (isPendingSpace) {
    return <Spinner />;
  }

  const createNewRow = (linkTypeId) => {
    run(
      apiClient(`workflow/document-type/${space?.document_type?.id}/document`, {
        data: {
          links: [{ id: document?.id, type: linkTypeId }],
        },
        method: "POST",
      })
    )
      .then((response) => {
        reload().then(() => {
          if (gridRef.current) {
            const total = (pagination?.total ?? 0) + 1;

            const lastPage = Math.ceil(total / limit);
            const lastPageSkip = (lastPage - 1) * limit;
            const p = 1 + parseInt(lastPageSkip / limit);
            setSkip(lastPageSkip);
            setPage(p);
            gridRef.current.setSkip(lastPageSkip);
            const interval = setInterval(() => {
              gridRef.current.scrollToId(response?.id);
              // gridRef.current.startEdit({ id: response?.id });
            }, 100);
            setTimeout(() => clearInterval(interval), 3000);
          }
        });
      })
      .catch(() => {
        toast.error(t("new_error"));
      });
  };

  const footerRows = [
    {
      render: columns
        .filter((c) => c.summary || c?.name == "name")
        .map((c) => ({
          ...c,
          name: c.name,
          value: 0,
        }))
        .reduce((acc, c, idx) => {
          if (c?.name == "name") {
            const creatable =
              space?.document_type?.creatable === true ||
              space?.document_type?.creatable === "true";
            const createFromFooter =
              config?.createFromFooter == true ||
              config?.createFromFooter == "true";
            if (creatable && createFromFooter) {
              acc[c.name] = () => (
                <Button
                  appearance="primary"
                  isLoading={isCreating}
                  shouldFitContainer
                  width="100%"
                  onClick={() => {
                    createNewRow(
                      config?.createFromFooter?.createFromFooterLinkTypeId ?? 3
                    );
                  }}
                  size="small"
                >
                  {t("new_row_button")}
                </Button>
              );
            }

            return acc;
          }

          const col = columns?.find((cl) => cl?.name == c?.name);
          // eslint-disable-next-line react/display-name
          acc[c.name] = () => (
            <Summary
              hash={hash}
              column={c}
              documentTypeId={documentTypeId}
              align={col?.align ?? "center"}
              name={c.name}
              filters={allFilters}
              addFormOpen={addFormOpen}
              documentFilterId={document?.id}
            />
          );
          // acc[c.name] = ({ summary }) => <FooterRow align={col?.align ?? "center"}>{summary[c.name] ?? "-"}</FooterRow>;
          return acc;
        }, {}),
    },
  ];

  const summaryReducer = {
    initialValue: columns
      .filter((c) => c.summary)
      .map((c) => ({
        name: c.name,
        value: 0,
      }))
      .reduce((acc, c) => {
        acc[c.name] = 0;
        return acc;
      }, {}),
    reducer: (accumulator, item) => {
      // columns.filter(c => c.summary).map(c => {
      //     const docId = item.id;
      //     const doc = documents?.data?.find(i => i?.id == docId);

      //     if (!doc) return;

      //     const value = parseFloat((doc?.values[c.name] ?? "0")?.replace(",", "."));

      //     if (!isNaN(value)) {
      //         accumulator[c.name] += value;
      //     }
      // });

      return accumulator;
    },
    complete: (acc, _) => {
      columns
        .filter((c) => c.summary)
        .filter((c) => c?.fixed)
        .map((c) => {
          acc[c.name] = Number(acc[c.name]).toFixed(c?.fixed);
        });

      columns
        .filter((c) => c.summary)
        .filter((c) => c?.currency)
        .map((c) => {
          acc[c.name] = parseCurrency(acc[c.name], c?.currency);
        });

      columns
        .filter((c) => c.summary)
        .filter((c) => c?.number)
        .map((c) => {
          acc[c.name] = new Intl.NumberFormat()
            .format(acc[c.name])
            .replaceAll(",", " ")
            .replace(".", ",");
        });

      return acc;
    },
  };

  const download = (format) => {
    exportList(format)
      .then((data) => {
        let element = window.document.createElement("a");
        element.setAttribute("href", URL.createObjectURL(data));
        element.setAttribute("download", `${slug}.${format}`);
        element.style.display = "none";
        window.document.body.appendChild(element);
        element.click();
        window.document.body.removeChild(element);
        setTimeout(() => {
          URL.revokeObjectURL(data);
          element.remove();
        }, 100);
      })
      .catch((e) => {
        toast.error(t("space_export_error"));
      });
  };

  const newDownload = (format) => {
    setExporting(true);
    newExportList(format, document?.id)
      .then((data) => {
        const url = data?.url;
        if (url) {
          window.open(url, "_blank");
          // window.location.href = url;
        } else {
          toast.error(t("space_export_error"));
        }
        // let element = document.createElement("a");
        // element.setAttribute("href", url);
        // element.setAttribute("download", `${slug}.${format}`);
        // element.style.display = "none";
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);
        // setTimeout(() => {
        //     URL.revokeObjectURL(data);
        //     element.remove();
        // }, 100);
      })
      .catch((e) => {
        toast.error(t("space_export_error"));
      })
      .finally(() => setExporting(false));
  };

  const reloadAll = () => {
    reload();
    onReload && onReload();
    setHash(newHash());
  };

  const columnsFlex = columns?.reduce((acc, c) => {
    acc[c?.name] = c?.flex ?? undefined;
    return acc;
  }, {});

  const creatable =
    space?.document_type?.creatable === true ||
    space?.document_type?.creatable === "true";

  return (
    <>
      {!documentTypeId && <DocumentTypeError />}
      {documentTypeId && (
        <DocumentsListWrapper>
          {filtersEnabled && (
            <>
              {space && space.name && (
                <Helmet>
                  <title>{`${listName(
                    document,
                    currentSection
                  )} - ${documentName(document)} - ${title}`}</title>
                </Helmet>
              )}
              <PageHeader
                id="list-page-header"
                actions={
                  <ButtonGroup>
                    <FiltersDropdown
                      space={space}
                      fields={fields}
                      rootDocumentTypeId={document?.document_type_id}
                      parentDocumentTypeId={documentTypeId}
                      onChange={(e) => {
                        setFilters(e);
                      }}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                    />
                    <HeaderButtonWrapper>
                      <Button onClick={() => setFiltersOpen((value) => !value)}>
                        <span>{t("documents_filters")}</span>
                      </Button>
                    </HeaderButtonWrapper>
                    {exportEnabled && (
                      <HeaderButtonWrapper>
                        <DropdownMenu
                          position="bottom right"
                          triggerType="button"
                          trigger="Export"
                        >
                          <DropdownItemGroup>
                            <DropdownItem onClick={() => newDownload("csv")}>
                              CSV
                            </DropdownItem>
                            <DropdownItem onClick={() => newDownload("xlsx")}>
                              XLSX
                            </DropdownItem>
                          </DropdownItemGroup>
                        </DropdownMenu>
                      </HeaderButtonWrapper>
                    )}
                    {!hideCreate && creatable && !disableCreate && (
                      <HeaderButtonWrapper>
                        <Button
                          appearance="primary"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setAddFormOpen(true);
                          }}
                        >
                          {t("new_button")}
                        </Button>
                      </HeaderButtonWrapper>
                    )}
                  </ButtonGroup>
                }
              >
                {listName(document, currentSection)}
              </PageHeader>
            </>
          )}
          {filtersEnabled && filtersOpen && (
            <FiltersArea>
              <Filters
                documentTypeId={documentTypeId}
                initialFilters={filters?.filters}
                initialLinks={filters?.links}
                initialType={filters?.type}
                trashed={trashed}
                setTrashed={(e) => {
                  setPage(1);
                  setSkip(0);
                  setTrashed(e);
                }}
                onAccept={(e) => {
                  setPage(1);
                  setFilters(e);
                  setFiltersOpen(false);
                  reloadSpace && reloadSpace();
                  setSelectedFilter(e?.newFilterId ?? null);
                }}
                onCancel={() => setFiltersOpen(false)}
                onHide={() => setFiltersOpen(false)}
                statuses={statuses}
                sortColumn={sortColumn}
                sortDir={sortDir}
                saveFor={{
                  rootDocumentTypeId: document?.document_type_id,
                  parentDocumentTypeId: documentTypeId,
                }}
                columnsVisible={columnsVisible}
                columns={[
                  {
                    id: "name",
                    label:
                      fields?.find((i) => i?.name === "name")?.label ??
                      t("column_name"),
                    name: "name",
                    type: "text",
                  },
                  {
                    id: "users",
                    label: t("column_users"),
                    name: "users",
                    type: "users",
                  },
                  ...documentsColumns,
                  ...(availableDocumentTypes?.map((dt) => ({
                    id: dt.value,
                    label: dt.label,
                    name: dt.value,
                    type: "document_type",
                  })) || []),
                ]}
              />
            </FiltersArea>
          )}
          {exporting && (
            <SectionMessageWrapper>
              <SectionMessage title="Export..." appearance="information">
                <Spinner />
              </SectionMessage>
            </SectionMessageWrapper>
          )}
          <DocumentsList
            disableCreate={disableCreate}
            disableEdit={disableEdit}
            disableDelete={disableDelete}
            isPending={isPending || isPendingSpace}
            fields={filteredFields}
            documents={documents}
            editMode={editMode}
            actionsColumn={editMode}
            trashed={trashed}
            createButton={createButton}
            colorField={colorField}
            colorColumn={colorColumn}
            pagination={pagination}
            bgField={bgField}
            addFormOpen={addFormOpen}
            setAddFormOpen={setAddFormOpen}
            height={500}
            linkTypeId={linkTypeId}
            summaryReducer={summaryReducer}
            footerRows={footerRows}
            related={related}
            contextDocument={document}
            document_links={document_links}
            columnsFlex={columnsFlex}
            isDrawer={isDrawer ?? false}
            onSkipChange={(s) => {
              updatePage(limit, parseInt(s));
            }}
            onLimitChange={(l) => {
              // updatePage(parseInt(l), skip);
              setLimit(l);
              setPage(1);
              setSkip(0);
            }}
            limit={limit}
            skip={skip}
            availableDocumentTypes={availableDocumentTypes}
            savedColumns={savedColumns}
            sortHandle={sortHandle}
            sortColumn={sortColumn}
            sortDir={sortDir}
            columnsOrder={columnsOrder}
            onColumnOrderChange={setColumnsOrder}
            columnVisible={columnsVisible}
            onColumnVisibleChange={onColumnVisibleChange}
            space={space}
            showColumnMenuTool={showColumnMenuTool}
            columnsWidths={columnsWidths}
            columnsGroups={columnsGroups}
            defaultColumnWidth={defaultColumnWidth}
            reload={reloadAll}
            onRefCreated={(ref) => setGridRef(ref)}
            setSkip={setSkip}
            setPage={setPage}
            setColumnsOrder={setColumnsOrder}
            columns={columns}
          />
        </DocumentsListWrapper>
      )}
    </>
  );

  function DocumentTypeError() {
    return (
      <SectionMessage appearance="error" testId="alert" title="Error">
        {t("documents_no_document_type")}
      </SectionMessage>
    );
  }
}

export default Documents;

const ErrorMessageWrapper = styled.div`
  padding: 20px;
  color: black;
`;

const DocumentsListWrapper = styled.div`
  max-width: 100%;
  margin-top: 10px;
`;

const FiltersArea = styled.div`
  border-radius: 5px;
  padding: 20px;
  margin: 20px 0;
  background: #fafbfc;
`;

const HeaderButtonWrapper = styled.div`
  margin-left: 10px;
`;

function listName(document, currentSection) {
  const linkable = document?.linkable?.find(
    (l) => decodeURI(currentSection) == l?.name
  );
  return linkable?.config?.alias ?? linkable?.label;
}

const FooterRow = styled.div`
  text-align: ${(props) => props.align ?? "center"};
  display: block;
  width: 100%;
`;

const FiltersDropdown = ({
  rootDocumentTypeId,
  parentDocumentTypeId,
  space,
  onChange,
  fields,
  selectedFilter,
  setSelectedFilter,
}) => {
  const { t } = useTranslation();

  if (!space?.document_type?.filters) return null;
  const filters = space?.document_type?.filters
    ?.filter((f) => f?.parent_document_type_id == parentDocumentTypeId)
    ?.filter((f) => f?.root_document_type_id == rootDocumentTypeId);

  if (!filters) return null;

  return (
    <div style={{ width: "200px" }}>
      <Select
        placeholder={t("documents_filters")}
        isClearable
        options={
          filters?.map((i) => ({
            label: i?.name,
            value: i?.id,
          })) ?? []
        }
        // value={filters?.find(i => i?.id == selectedFilter)?.map(i => ({
        //     label: i?.name,
        //     value: i?.id,
        // }))}
        value={
          filters?.find((i) => i?.id == selectedFilter)
            ? {
                label: filters?.find((i) => i?.id == selectedFilter)?.name,
                value: filters?.find((i) => i?.id == selectedFilter)?.id,
              }
            : null
        }
        // value={{
        //     label: valueText(view),
        //     value: view,
        // }}
        onChange={(e) => {
          const selected = filters?.find((i) => i?.id == e?.value)?.data;

          if (!selected) {
            onChange(null);
            setSelectedFilter(null);
            return;
          }

          if (selected && onChange) {
            console.log("SELECTED", selected);
            const fieldsMap = fields?.reduce((acc, f) => {
              acc[f?.name] = f?.field?.type;
              return acc;
            }, {});

            const filt = {
              filters: selected.filters.map((f) => ({
                ...f,
                id: uuidv4(),
                type: fieldsMap[f?.name],
                column: f?.name,
              })),
              type: selected.condition,
              sortColumn: selected.sort_column ?? undefined,
              sortDir: selected.sort_dir ?? undefined,
              columnsVisible: selected.columns_visible ?? undefined,
            };

            filt.filters =
              filt.filters?.map((f) => {
                try {
                  const val = JSON.parse(f?.value);

                  if (typeof val !== "object" || val == null) return f;

                  return {
                    ...f,
                    name: parseInt(f?.name),
                    column: parseInt(f?.column),
                    value: val,
                    type: "document_type",
                  };
                } catch (e) {
                  return f;
                }
              }) || [];

            // if (filt?.sortColumn) setSortColumn(filt?.sortColumn);
            // if (filt?.sortDir) setSortDir(filt?.sortDir);
            // if (filt?.columnsVisible && !window.localStorage.getItem(localStorageKey)) setColumnsVisible(filt?.columnsVisible);

            onChange(filt);
            setSelectedFilter(e?.value);
          }
        }}
      />
    </div>
  );
};

const Summary = ({
  name,
  align,
  filters,
  documentTypeId,
  column,
  addFormOpen,
  hash,
  documentFilterId,
}) => {
  const { isPending, run, data } = useAsync();
  const [loaded, setLoaded] = useState(false);

  const params = {
    condition: filters?.type,
    filters: filters?.filters
      ?.filter((i) => i?.type !== "document_type" && i?.column !== "users")
      ?.map(mapFilter),
    links: filters?.filters
      ?.filter((i) => i?.type === "document_type")
      ?.map((i) => ({
        operator: "=",
        document_id: i.value?.value,
      })),
    users: filters?.filters
      ?.filter((i) => i?.column === "users")
      ?.map((i) => ({
        operator: i?.operator,
        value: i.value?.id,
      })),
  };

  useEffect(() => {
    run(
      apiClient(
        `document-types/${documentTypeId}/summary/${name}?hash=${hash}&linked_with=${
          documentFilterId ?? ""
        }`,
        {
          method: "POST",
          data: params,
        }
      )
    );
    setLoaded(true);
  }, [addFormOpen, hash]);

  if (isPending && !loaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <FooterRow align={column?.align ?? "center"}>
      {formatSummary(column, data)}
    </FooterRow>
  );
};

const formatSummary = (c, value) => {
  if (c?.fixed) {
    return Number(value).toFixed(c?.fixed);
  }

  if (c?.currency) {
    return parseCurrency(value, c?.currency);
  }

  if (c?.number) {
    return new Intl.NumberFormat()
      .format(value)
      .replaceAll(",", " ")
      .replace(".", ",");
  }

  return value;
};

const SectionMessageWrapper = styled.div`
  margin-bottom: 20px;
`;
